<template>
    <footer class="bg-slate-900 text-slate-200 pt-16 lg:pt-28">
        <UContainer>
            <div class="grid md:grid-cols-2 lg:grid-cols-12 gap-10 pb-12 items-start">
                <div class="lg:col-span-5">
                    <NuxtImg src="images/logo-dark.svg" densities="x1" loading="lazy" />
                    <div class="mt-8 md:mt-3">When you bring your vehicle to OCAUTOAID in Irvine, you can trust that it's in expert hands. Our team is dedicated to providing a smooth and efficient experience, making sure your visit to our Irvine car service center is both pleasant and reliable.</div>
                </div>

                <div class="grid gap-5 lg:col-span-2">
                    <h2 class="text-xl font-bold">Navigation</h2>

                    <nav class="grid gap-6">
                        <ULink v-for="item of navigation" :to="item._path" class="font-bold hover:underline">{{item.title}}</ULink>
                    </nav>
                </div>

                <div class="grid gap-5 lg:col-span-3">
                    <h2 class="text-xl font-bold">Contact Info</h2>

                    <div class="grid gap-7.5">
                        <div>
                            <h3 class="text-lg">Address:</h3>
                            <address class="not-italic">
                                <ULink to="#" class="text-blue-500 underline hover:no-underline">515 Hamilton St, Costa Mesa, CA 92627</ULink>
                            </address>
                        </div>
                        <div>
                            <h3 class="text-lg">Contact Number:</h3>
                            <ULink to="tel:+1 (123) 456-7890" class="text-blue-500 underline hover:no-underline">+1 (123) 456-7890.</ULink>
                        </div>
                        <div>
                            <h3 class="text-lg">Email:</h3>
                            <ULink to="mailto:OCAUTOAID@gmail.com" class="text-blue-500 underline hover:no-underline">OCAUTOAID@gmail.com</ULink>
                        </div>
                    </div>
                </div>

                <div class="grid gap-5 lg:col-span-2">
                    <h2 class="text-xl font-bold">Navigation</h2>

                    <div>
                        <h3 class="font-semibold">Irvine</h3>
                    </div>

                    <div>
                        <h3 class="text-lg font-bold mb-5">Business Hours:</h3>
                        <div class="font-semibold">Mon - Fri 8:00 AM - 6:00 PM<br>Sat 8:00 AM - 5:00 PM</div>
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 gap-4 py-5 text-slate-300">
                <div>
                    <ul class="flex gap-4">
                        <li>
                            <ULink to="/privacy-policy">Privacy Policy</ULink>
                        </li>
                    </ul>
                </div>
                <div class="md:flex md:justify-end">© {{new Date().getFullYear()}} All Rights Reserved | OCAUTOAID</div>
            </div>
        </UContainer>
    </footer>
</template>
<script setup lang="ts">
const {data: navigation} = await useAsyncData('navigation', () => fetchContentNavigation());
</script>
